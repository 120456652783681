import React from 'react'
import SEO from '../components/seo'
import Container from '../components/container'
import Layout from '../containers/layout'
import Hero from '../components/modules/Hero'


export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(_id: { regex: "/(drafts.|)404/" }) {
      id
      header
      _rawBody
    }
  }
`

const NotFoundPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const page = (data || {}).page
  
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Hero page={page} basic />
      </Container>
    </Layout>
  )
}


export default NotFoundPage
